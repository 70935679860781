import React from "react";
import { Link } from "react-router-dom";
import TuoImmagine from './img/750611.JPEG';

export default function About(){
  return (
    <section id="about">
      <a name="contact" />
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm Manuel.
            <br className="hidden lg:inline-block" /> I love to build amazing
            apps and websites.
          </h1>
          <p className="mb-8 leading-relaxed">
            I deal with web development and web design. I create tailor-made websites, web applications or no-code applications based on needs.
            <br></br><br/>
            Talking about websites, I mainly design showcase pages, or blogs. Therefore also adding a database in which to write the contents.
            <br></br><br></br>
            While for web applications or apps, I focus on creating products that can be used in the company, such as management software, or that simplify work and improve productivity.
          </p>
          <div className="flex justify-center">
            <a
              href="#contact"
              className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">
              Work With Me
            </a>
            <Link to="/myworks"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              See My Past Work
            </Link>
            <a
              href="https://github.com/Manueee02?tab=repositories"
              className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
              My github
            </a>
          </div>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6" style={{ height: '25rem' }}>
          <img
            className="object-cover object-center rounded"
            alt="me"
            src={TuoImmagine}
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '1rem' }}
          />
        </div>

      </div>
    </section>
  )
}