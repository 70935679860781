export const projects = [

    {
      title: "Real-Time Chat",
      subtitle: "Try it",
      description:
        "A real-time chat application, where you can connect with anyone currently online. Integrated in the applications there are the login process and also the subscription process.",
      image: "https://i.ibb.co/0Ygpfdz/Screenshot-2024-05-30-134257.png",
      link: "https://realtime-chat-app-04-1a68095f7528.herokuapp.com/",
    },
    {
      title: "Centro Zeta",
      subtitle: "civil protection",
      description:
        "A website with three blog sections for a civil protection association, where they talk about their mission, publish documents, posts and photos regarding their interventions. ",
      image: "https://i.ibb.co/dM2VdMN/centrozeta.png",
      link: "https://centrozeta.it",
    },
    {
      title: "Proximity",
      subtitle: "app to simplify your work",
      description:
        "It's a very simple application created with Google Appsheet, which allowed you to scan different products. It cross-referenced two lists of data, to let you know which products you should analyze.",
      image: "https://i.ibb.co/f0GMp3z/Ls-Organization-5.png",
      link: "",
    },
    {
      title: "Ls Organization",
      subtitle: "management application",
      description:
        "A no code management application, which aims to manage and organize a team of employees. With this application you can also send and receive reports, so you can see and manage the completed activity.",
      image: "https://i.ibb.co/mFBg47p/Ls-Organization.jpg",
      link: "",
    },
    
  ];