//import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import React from "react";


export default function Skills() {
  const skills = ["Html5", "Css3", "Javascript"]
  const skills4 = ["React Js"]
  const skills2 = ["MySql", "NoSql", "Firebase"]
  const skills3 = ["Google Appsheet"]
  const skills5 = ["Git", "Figma"]
  return (
    <section id="skills">
      <a name="skills"></a>
      <div className="container px-5 py-10 mx-auto">
        <div className="text-center mb-20">
          {/*<ChipIcon className="w-10 inline-block mb-4" />*/}
          <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
            Skills &amp; Technologies
          </h1>
          
        </div>
        <h3>Programming languages:</h3>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          
          {skills.map((skill) => (
            <div key={skill} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center" id="span1">
                <span className="title-font font-medium text-white">
                  {skill}
                </span>
              </div>
            </div>
          ))} 
        
        </div><br/>
        <h3>Framework:</h3>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          
          {skills4.map((skill) => (
            <div key={skill} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center" id="span2">
                <span className="title-font font-medium text-white">
                  {skill}
                </span>
              </div>
            </div>
          ))} 
        
        </div><br/>
        <h3>Database:</h3>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          
          {skills2.map((skill) => (
            <div key={skill} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center" id="span3">
                <span className="title-font font-medium text-white">
                  {skill}
                </span>
              </div>
            </div>
          ))} 
        
        </div><br/>
        <h3>Experince with:</h3>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          
          {skills5.map((skill) => (
            <div key={skill} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center" id="span4">
                <span className="title-font font-medium text-white">
                  {skill}
                </span>
              </div>
            </div>
          ))} 
        
        </div><br></br>
        <h3>No-Code:</h3>
        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
          
          {skills3.map((skill) => (
            <div key={skill} className="p-2 sm:w-1/2 w-full">
              <div className="bg-gray-800 rounded flex p-4 h-full items-center" id="span4">
                <span className="title-font font-medium text-white">
                  {skill}
                </span>
              </div>
            </div>
          ))} 
        
        </div>




        </div>
      
    </section>
  );
}