// src/components/Contact.js

import React from "react";
import emailjs from "emailjs-com";
// npm install emailjs-com
// npm install react-toastify
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

import sfondo from './img/1.jpg'


class Contact extends React.Component{
  /*const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  function handleSubmit(e) {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message }),
    })
      .then(() => alert("Message sent!"))
      .catch((error) => alert(error));
  }*/
  constructor(props) {
    super(props);
    this.state = {
      Name:'',
      Email:'',
      Message:'',

    };

    // Necessario per accedere al corretto valore di this all'interno della callback
    this.handleInputChange = this.handleInputChange.bind(
      this
    );

    
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // senza la bind this potrebbe essere undefined
    this.setState({
      [name]: value
    });
  }

  inviaEmail(e) {
    // preventDefault annulla l'evento di default
     e.preventDefault();
     
     /*emailjs.sendForm('service_espositori', 'template_v61egh9', e.target, 'DVR6NlCr-hzT7HZsK')
            .then((result) => {
               toast.success("Email inviata con successo");
               console.log(result.text);
            }, (error) => {
               toast.error("Invio Email non riuscito");
               console.log(error.text);
            });*/

            

     // reset degli input del form contattaci
     emailjs.sendForm('service_81jvmpa', 'template_0noxc92', e.target, 'DVR6NlCr-hzT7HZsK')
            .then((result) => {
              toast.success('Sent successfully')
            }, (error) => {
              toast.error("Error in sending")
            });
       
     e.target.reset();

     var vis = 1000;

   //ALERT
   /*
   window.alert = function (message) {
   //Creazione dell'elemento
     var a = document.createElement('div');
     //regole di stile CSS
     a.style.cssText = "width:20rem; height:4rem; border:1px solid #bbb; border-radius:5px; padding:10px; background:white; box-shadow:0px 0px 8px #0006; position:fixed; top:0px; right:0; left:0; bottom: 10rem; margin:auto; font-family: \"Arial\", sans-serif; color:black; text-align:center; z-index:"+ vis+ ";";

     a.innerHTML = "<b>"+message+'</b><i class="gg-chevron-down-o" style="margin-top:1rem; left:46%; right:50%;"></i>';
     document.body.appendChild(a);

     vis--;

     //Rimozione dell'elemento al click
     a.addEventListener("click", function() {
       a.remove();
       }
     )
   };*/

   
 }


  render(){
  return (
    <section id="contact" className="relative">
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <link href='https://unpkg.com/css.gg@2.0.0/icons/css/chevron-down-o.css' rel='stylesheet'></link>
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <img
            width="100%"
            height="130%"
            title="map"
            className="absolute inset-0"
            frameBorder={0}
            marginHeight={0}
            marginWidth={0}
            style={{ filter: "opacity(0.7)" , borderRadius:"1rem"}}
            src={sfondo}
          />
          <div className="bg-gray-800 relative flex flex-wrap py-9 rounded shadow-md">
            
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-white tracking-widest text-xs">
                EMAIL
              </h2>
              <a className="text-indigo-400 leading-relaxed">
                manuel02.web@gmail.com
              </a>
              <br></br>
              <br></br>
              <a href="https://www.linkedin.com/in/manuel-moschetti-596296172/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2x" className="icon"/>
              </a>

              <a href="https://www.instagram.com/il_tuo_account/" target="_blank" rel="noopener noreferrer" className=" hover:text-white">
                <FontAwesomeIcon icon={faInstagram} size="2x" className="icon" style={{marginLeft:"10px"}} />
              </a>
            </div>
          </div>
        </div>
        <form
          netlify
          name="frmEmail" 
          method="post" 
          action="?send=1" 
          onSubmit={this.inviaEmail}
          className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            Hire Me
          </h2>
          <p className="leading-relaxed mb-5">
            Do you need a website or application for yourself or your company?<br/> 
            Contact me or fill out the form. <br/><br/>
            Enjoy the technology!!
          </p>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-400">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-400">
              Email
            </label>
            <input
              type="email"
              id="email"
              required
              name="Email"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-400">
              Message
            </label>
            <textarea
              id="message"
              name="Message"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              onChange={this.handleInputChange}
            />
          </div>
          <button
            type="submit"
            name="submit"
            className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}}
export {Contact}