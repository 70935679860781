
import './App.css';
import React from "react";

import About from "./components/About";
import { Contact } from './components/Contact';
import Skills from "./components/Skills";
import Projects from './components/Projects';



import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';


function App() {
  

  return (
  <main className="text-gray-400 bg-gray-900 body-font">
    <BrowserRouter>
      <header className="bg-gray-800 md:sticky top-0 z-10" >
      
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">

  {/* Navigation Links - Use flex-row for both mobile and larger screens */}
  <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700
               flex flex-row text-base">
    <Link to="/" className="mr-2 text-white hover:text-white">
      <button className="text-xl">Home</button>
    </Link>
    <div className="border-t border-gray-700 h-6 my-3 md:hidden"></div>
    <Link to="/myworks" className="mr-2 text-gray-600 hover:text-white">
      <button className="text-xl">My Works</button>
    </Link>
    <Link to="/myskills" className="text-gray-600 hover:text-white">
      <button className="text-xl">My Skills</button>
    </Link>
  </nav>

  {/* Hire Me Link */}
  <a
    href='#contact' // Update to your contact page route
    id='contact_23'
    className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
  >
    Hire Me
    {/*<ArrowRightIcon className="w-4 h-4 ml-1" />*/}
  </a>
</div>
        
      
      
      </header>
      <Routes>
        <Route path="/" element={<About />}></Route>
        <Route path="/myworks" element={<Projects />}></Route>
        <Route path="/myskills" element={<Skills />}></Route>
      </Routes>
    </BrowserRouter>
    
    
    <Contact />
  </main>
  );
}

export default App;
